import { useCallback, useRef } from 'react';
import { InspectionResultRequestData } from 'types/api/extendedWarranty';
import * as ewApi from 'api/extendedWarranty';
import { UploadFile } from 'antd/es/upload/interface';
import { File } from 'types/api/file';
import { BanksForUplus } from 'types/extendedWarranty/common';
import { message } from 'antd';
import axios, { CancelTokenSource } from 'axios';
import { UnRegisterReasons, UplusPaymentMethod } from 'constants/extendedWarranty';
import { useLoading } from 'hooks/extendedWarranty/admin/common';

interface Params {
	identificationFile: UploadFile<File>[];
	dashboardFile: UploadFile<File>[];
	inspectionFile: UploadFile<File>[];
	mileage: string;
	join?: boolean | 0 | 1;
	haveExclusion?: boolean;
	exclusion: string;
	unregisterReason: string;
	bank: BanksForUplus | '';
	bankAccount: string;
	bankAccountHolder: string;
	payMethod: UplusPaymentMethod | string;
}

interface Options {
	isTempSave?: boolean;
}

type Return = [(id: number, params: Params, options?: Options) => Promise<boolean>, boolean];

const { CancelToken } = axios;

function useSaveInspection(): Return {
	const [loading, setLoading] = useLoading(false);
	const lastFetch = useRef<CancelTokenSource>();

	const save = useCallback(async (
		id,
		data: Params,
		options?: Options,
	): Promise<boolean> => {
		const { isTempSave = false } = options || {};
		const notInspection = !data.join && data.unregisterReason === UnRegisterReasons.미점검;

		if (!isTempSave) {
			if (!notInspection && data.identificationFile.length === 0) {
				message.warning('차대번호 이미지를 등록해 주세요.');
				return false;
			}
			if (!notInspection && data.dashboardFile.length === 0) {
				message.warning('차량계기판 이미지를 등록해 주세요.');
				return false;
			}
			if (!notInspection && data.inspectionFile.length === 0) {
				message.warning('점검결과지 이미지를 등록해 주세요.');
				return false;
			}
			if (!notInspection && (!data.mileage || parseInt(data.mileage, 10) < 1)) {
				message.warning('현재 주행거리를 입력해 주세요.');
				return false;
			}
			if (data.join == null) {
				message.warning('보증 가입여부를 선택해 주세요.');
				return false;
			}
			if (data.join && data.haveExclusion == null) {
				message.warning('보증제외 항목을 선택해주세요.');
				return false;
			}
			if (data.join && data.haveExclusion && !data.exclusion) {
				message.warning('제외항목을 입력해주세요.');
				return false;
			}
			if (!data.join && !data.unregisterReason) {
				message.warning('미가입사유를 선택해 주세요.');
				return false;
			}
			if (!data.join
				&& data.payMethod === UplusPaymentMethod.VIRTUAL_ACCOUNT
				&& !data.bankAccount) {
				message.warning('환불 계좌번호를 입력해 주세요.');
				return false;
			}
			if (!data.join
				&& data.payMethod === UplusPaymentMethod.VIRTUAL_ACCOUNT
				&& !data.bank) {
				message.warning('환불 계좌의 은행을 선택해 주세요.');
				return false;
			}
			if (!data.join
				&& data.payMethod === UplusPaymentMethod.VIRTUAL_ACCOUNT
				&& !data.bankAccountHolder) {
				message.warning('예금주를 입력해 주세요.');
				return false;
			}
		}

		try {
			const identificationImage = data.identificationFile[0]?.response;
			const dashboardImage = data.dashboardFile[0]?.response;
			const inspectionImage = data.inspectionFile[0]?.response;
			const body: InspectionResultRequestData = {
				mileage: Number(data.mileage),
				identificationPicture: {
					key: identificationImage?.fileKey || '',
					name: identificationImage?.fileOrgName || '',
				},
				dashboardPicture: {
					key: dashboardImage?.fileKey || '',
					name: dashboardImage?.fileOrgName || '',
				},
				inspectionPicture: {
					key: inspectionImage?.fileKey || '',
					name: inspectionImage?.fileOrgName || '',
				},
			};

			if (!isTempSave) {
				if (data.join == null) {
					message.warning('보증 가입여부를 선택해주세요.');
					return false;
				}
				body.extendWarrantyAvailable = !!data.join;
			}

			if (data.join) {
				if (data.haveExclusion != null) {
					body.extendWarrantyExclusion = {
						has: data.haveExclusion,
						contents: data.haveExclusion ? data.exclusion : '',
					};
				}
			}

			if (data.join != null && !data.join && data.unregisterReason) {
				body.reasonForNotJoin = data.unregisterReason;
			}

			if (data.bank || data.bankAccount || data.bankAccountHolder) {
				body.refundAccount = {
					bank: data.bank || null,
					number: data.bankAccount || null,
					name: data.bankAccountHolder || null,
				};
			}

			if (!isTempSave) setLoading(true);

			if (lastFetch.current) {
				lastFetch.current.cancel();
			}

			const source = CancelToken.source();
			lastFetch.current = source;
			await ewApi.postInspectionResult(id, body, source.token);
		} catch (e) {
			setLoading(false);
			if (!axios.isCancel(e)) {
				console.error(e);
				throw e;
			}
		} finally {
			setLoading(false);
		}
		return true;
	}, [setLoading]);

	return [save, loading];
}

export default useSaveInspection;
