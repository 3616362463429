import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as ewApi from 'api/extendedWarranty';
import { ReservationByHourly } from 'types/api/extendedWarranty';

interface WorkingState {
	list: ReservationByHourly[] | null;
	totalCount: number;
	loading: boolean;
	error: boolean;
}

export const initialState: WorkingState = {
	list: null,
	totalCount: 0,
	loading: false,
	error: false,
};

export const fetchWorkingList = createAsyncThunk(
	'working/fetchWorkingList',
	async (branchId: number) => {
		const { data } = await ewApi.getWorkingList(branchId);
		return data;
	},
);

export default createSlice({
	name: 'working',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchWorkingList.pending, (state): void => {
			state.loading = true;
			state.error = false;
		});
		builder.addCase(fetchWorkingList.rejected, (state): void => {
			state.loading = false;
			state.error = true;
		});
		builder.addCase(fetchWorkingList.fulfilled, (state, action): void => {
			state.loading = false;
			state.list = action.payload;
			state.totalCount = action.payload.length;
		});
	},
});
