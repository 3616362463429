import React from 'react';
import dayjs, { Dayjs } from 'libs/dayjs';
import { ColumnsType } from 'antd/es/table';
import { HYPHEN_DATE_FORMAT } from 'constants/extendedWarranty';

export const range = (start: number, end: number, step = 1): number[] => (
	Array.from({ length: (end - start) }, (v, k) => k + start + (step - 1))
);

export const typedMemo: <T>(c: T) => T = React.memo;

// eslint-disable-next-line @typescript-eslint/ban-types
export const memoize = <T>(fn: Function): Function => {
	const cache: {
		[key: string]: T;
	} = {};
	return (...args: string[]): T => {
		const n = args[0];
		if (n in cache) {
			return cache[n];
		}
		const result = fn(n);
		cache[n] = result;
		return result;
	};
};

export const dateRangeStringToMoment = (
	from?: string,
	to?: string,
): [Dayjs, Dayjs] | undefined => (
	from && to
		? [dayjs(from), dayjs(to)]
		: undefined);

export const dateRangeMomentToString = (value?: [Dayjs, Dayjs]): [string, string] | undefined => (
	value && value[0] && value[1]
		? [value[0].format(HYPHEN_DATE_FORMAT), value[1].format(HYPHEN_DATE_FORMAT)]
		: undefined);

export const filterColumnByKey = <T>(columns: ColumnsType<T>, keys: string[]): ColumnsType<T> => (
	columns.filter((c) => c.key && keys.indexOf(c.key as string) < 0)
);
