import * as Sentry from '@sentry/browser';

export default null;

/* eslint-disable-next-line */
export const loggingException = (error: Error, data?: Record<string, any>): void => {
	Sentry.withScope((scope) => {
		if (data) scope.setExtra('message', data);
		Sentry.captureException(error);
	});
};
