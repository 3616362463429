import React, { ReactElement } from 'react';
import classNames from 'classnames/bind';
import styles from './PageNotFound.module.scss';

const cx = classNames.bind(styles);

function PageNotFound(): ReactElement {
	return (
		<>
			<div className={cx('text')}>
				<h1>Page not found 404</h1>
			</div>
		</>
	);
}

export default PageNotFound;
