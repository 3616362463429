import {
	configureStore,
	getDefaultMiddleware,
	Middleware,
	Store,
	AnyAction,
} from '@reduxjs/toolkit';
import { buildEnv, ENVIRONMENT } from 'env';
import ewReducer from 'store/modules/extendedWarranty';
import dealerReducer from 'store/modules/dealer';
import crashReporter from 'middleware/crashReporter';
import { ServiceType } from 'types/common';
import { memoize } from 'libs/utils';

export default memoize<Store>((serviceType: ServiceType): Store => {
	const devTools = buildEnv === ENVIRONMENT.LOCAL;
	const middleware = getDefaultMiddleware().concat((crashReporter as Middleware));
	type ReducerType =
		typeof ewReducer |
		typeof dealerReducer;
	let reducer: ReducerType = ewReducer;

	if (serviceType === ServiceType.ENCAR_WARRANTY) reducer = ewReducer;
	if (serviceType === ServiceType.DEALER) reducer = dealerReducer;

	return configureStore<ReducerType, AnyAction, typeof middleware>({
		reducer,
		devTools,
		middleware,
	});
});
