import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'types/extendedWarranty/common';

interface AuthState {
	user: User | null;
	isLoggedIn: boolean | null;
}

interface SetAuthInfoPayload {
	user: User | null;
	isLoggedIn: boolean;
}

export const initialState: AuthState = {
	user: null,
	isLoggedIn: null,
};

export default createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthInfo: (state, action: PayloadAction<SetAuthInfoPayload>): void => {
			state.user = action.payload.user;
			state.isLoggedIn = action.payload.isLoggedIn;
		},
	},
});
