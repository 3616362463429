import {
	useState,
	useCallback,
	Dispatch,
	SetStateAction,
} from 'react';
import { Dayjs } from 'libs/dayjs';

type DateRangeValue = [Dayjs, Dayjs] | undefined;

interface Handler {
	(dates: [Dayjs, Dayjs], dateStrings: [string, string]): void;
}

type Return = [DateRangeValue, Handler, Dispatch<SetStateAction<DateRangeValue>>];

function useDateRangeState(initialState?: DateRangeValue): Return {
	const [value, setValue] = useState(initialState);
	const handler = useCallback((values: DateRangeValue): void => {
		setValue(values);
	}, []);

	return [value, handler, setValue];
}

export default useDateRangeState;
