import {
	useState,
	useCallback,
	Dispatch,
	SetStateAction,
} from 'react';
import { Dayjs } from 'libs/dayjs';

interface Handler {
	(date: Dayjs): void;
}

type Return = [
	Dayjs | undefined,
	Handler,
	Dispatch<SetStateAction<Dayjs | undefined>>,
];

function useDateState(initialState?: Dayjs): Return {
	const [value, setValue] = useState(initialState);
	const handler = useCallback((newValue?: Dayjs): void => {
		setValue(newValue);
	}, []);

	return [value, handler, setValue];
}

export default useDateState;
