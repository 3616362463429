import { useState } from 'react';
import { transformOrientationFile } from 'libs/file';

interface Return {
	transformImage: (file: File) => Promise<File>;
	loadingTransformImage: boolean;
}

function useTransformImage(): Return {
	const [loading, setLoading] = useState<boolean>(false);

	const transformImage = async (file: File): Promise<File> => {
		setLoading(true);
		const newFile = await transformOrientationFile(file);
		setLoading(false);
		return newFile;
	};

	return {
		transformImage,
		loadingTransformImage: loading,
	};
}

export default useTransformImage;
