import { Base64 } from 'js-base64';
import { JwtPayload } from 'types/extendedWarranty/common';
import dayjs from 'libs/dayjs';

export const decodeJwt = (jwt?: string | null): JwtPayload | null => {
	if (!jwt) return null;
	const payload = jwt.split('.')[1];
	return JSON.parse(Base64.decode(payload));
};

export const isValidJwt = (jwt?: string | null): boolean => {
	const payload = decodeJwt(jwt);
	if (payload?.exp) {
		return dayjs(payload.exp).isBefore(dayjs());
	}
	return false;
};

export const getBranchIdByJwt = (accessJwt: string | null): number | null => {
	const payload = decodeJwt(accessJwt);
	const branchPayload = payload?.authorities.find((a) => a.indexOf('BRANCH_') > -1) || '';
	return Number(branchPayload.split('_')[1]) || null;
};
