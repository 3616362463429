import { createSlice } from '@reduxjs/toolkit';

interface CountState {
	count: number;
}

export const initialState: CountState = {
	count: 0,
};

export default createSlice({
	name: 'count',
	initialState,
	reducers: {
		increase: (state): void => {
			state.count += 1;
		},
		decrease: (state): void => {
			state.count -= 1;
		},
	},
});
