import React, { useState, useCallback } from 'react';

interface ReturnOptionData {
	setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

type Return = [
	boolean,
	() => void,
	() => void,
	ReturnOptionData,
];

function useModalHandler(initialState: boolean): Return {
	const [show, setShow] = useState(initialState);
	const opener = useCallback((): void => { setShow(true); }, []);
	const closer = useCallback((): void => { setShow(false); }, []);

	return [show, opener, closer, { setShow }];
}

export default useModalHandler;
