export default null;

// export type ENVIRONMENT = 'local' | 'stage1' | 'stage2' | 'stage3' | 'dev' | 'prod';
export enum ENVIRONMENT {
	LOCAL = 'local',
	STAGE1 = 'stage1',
	STAGE2 = 'stage2',
	STAGE3 = 'stage3',
	DEV = 'dev',
	PROD = 'prod',
}
export const buildEnv = process.env.REACT_APP_ENV as ENVIRONMENT;

export const api = {
	local: 'http://apidev.encar.com',
	stage1: 'http://apidev.encar.com',
	stage2: 'http://apidev.encar.com',
	stage3: 'http://apidev.encar.com',
	dev: 'http://apidev.encar.com',
	prod: 'https://api.encar.com',
};

export const meyleApi = {
	local: 'http://publicmeyle.encar.io',
	stage1: 'http://publicmeyle.encar.io',
	stage2: 'http://publicmeyle.encar.io',
	stage3: 'http://publicmeyle.encar.io',
	dev: 'http://publicmeyle.encar.io',
	prod: 'https://meyle.encar.com',
};

export const sentryDsn = {
	local: 'https://f9c819391ed346e3a4c11f3d7d5a1173@o298382.ingest.sentry.io/4505327899901952',
	stage1: 'https://f9c819391ed346e3a4c11f3d7d5a1173@o298382.ingest.sentry.io/4505327899901952',
	stage2: 'https://f9c819391ed346e3a4c11f3d7d5a1173@o298382.ingest.sentry.io/4505327899901952',
	stage3: 'https://f9c819391ed346e3a4c11f3d7d5a1173@o298382.ingest.sentry.io/4505327899901952',
	dev: 'https://f9c819391ed346e3a4c11f3d7d5a1173@o298382.ingest.sentry.io/4505327899901952',
	prod: 'https://f9c819391ed346e3a4c11f3d7d5a1173@o298382.ingest.sentry.io/4505327899901952',
};
