import React, { FormEvent, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import logo from 'images/common/logo.png';
import { useInputState } from 'hooks/extendedWarranty/common';
import services from 'constants/services';
import classNames from 'classnames/bind';
import styles from './ServiceGateForm.module.scss';

const cx = classNames.bind(styles);

function ServiceGateForm(): ReactElement {
	const history = useHistory();
	const [serviceName, handleServiceName] = useInputState('');

	const handleSubmit = (e: FormEvent): void => {
		e.preventDefault();
		const service = services.find((s) => s.name.toLowerCase() === serviceName.toLowerCase()
			|| s.keywords.indexOf(serviceName) > -1);

		if (!service) {
			// eslint-disable-next-line no-alert
			alert('서비스명을 확인해주세요.');
			return;
		}

		history.push(service.root);
	};

	return (
		<form onSubmit={handleSubmit} className={cx('gate_form')}>
			<h1>
				<img className={cx('form_logo')} src={logo} alt="Trust Encar" />
			</h1>
			<input
				className={cx('input_service')}
				placeholder="서비스명을 입력해 주세요."
				value={serviceName}
				onChange={handleServiceName}
			/>
			<button type="submit" className={cx('btn_submit')}>확인</button>
		</form>
	);
}

export default ServiceGateForm;
