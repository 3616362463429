import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as ewApi from 'api/extendedWarranty';
import { Branch, ImportedProduct } from 'types/api/extendedWarranty';

interface CommonState {
	branch: {
		list: Branch[] | null;
		loading: boolean;
	};
	product: {
		list: ImportedProduct[] | null;
		loading: boolean;
	};
}

export const initialState: CommonState = {
	branch: {
		list: null,
		loading: false,
	},
	product: {
		list: null,
		loading: false,
	},
};

export const fetchBranches = createAsyncThunk(
	'common/fetchBranches',
	async () => {
		const { data } = await ewApi.getSimpleBranches();
		return data;
	},
);

export const fetchProducts = createAsyncThunk(
	'common/fetchProducts',
	async () => {
		const { data } = await ewApi.getProducts();
		return data;
	},
);

export default createSlice({
	name: 'common',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchBranches.pending, (state): void => {
			state.branch.loading = true;
		});
		builder.addCase(fetchBranches.rejected, (state): void => {
			state.branch.loading = false;
			state.branch.list = [];
		});
		builder.addCase(fetchBranches.fulfilled, (state, action): void => {
			state.branch.loading = false;
			state.branch.list = action.payload;
		});

		builder.addCase(fetchProducts.pending, (state): void => {
			state.product.loading = true;
		});
		builder.addCase(fetchProducts.rejected, (state): void => {
			state.product.loading = false;
			state.product.list = [];
		});
		builder.addCase(fetchProducts.fulfilled, (state, action): void => {
			state.product.loading = false;
			state.product.list = action.payload;
		});
	},
});
