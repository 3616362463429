import React, { useState, useCallback } from 'react';

function useDateRangeState<T>(initialState: T): [
	T,
	(value: T) => void,
	React.Dispatch<React.SetStateAction<T>>,
] {
	const [value, setValue] = useState(initialState);
	const handler = useCallback((newValue): void => {
		setValue(newValue);
	}, []);

	return [value, handler, setValue];
}

export default useDateRangeState;
