import {
	ewAxios,
	commonApi,
	getMeyleApiUrl,
	getApiUrl,
} from 'api';
import { AxiosPromise, CancelToken } from 'axios';
import { InspectionHistoryRequestParams, InspectionRequestParams } from 'types/extendedWarranty/inspect';
import { RepairsRequestParams } from 'types/extendedWarranty/repair';
import {
	BranchesRequestParams,
	BranchesSimpleRequestParams,
	ExcelDownloadLoggingRequestParams,
} from 'types/extendedWarranty/common';
import { VoucherRequestParams } from 'types/extendedWarranty/voucher';
import { InspectionDetail, InspectionResultRequestData, RepairEstimateRequestData } from 'types/api/extendedWarranty';
import { Base64 } from 'js-base64';
import { ReservationDailyRequestParams, ReservationHourlyRequestParams } from 'types/extendedWarranty/reservation';
import dayjs, { Dayjs } from 'libs/dayjs';
import {
	ACCESS_TOKEN_KEY,
	HYPHEN_DATE_FORMAT, InspectionStatus,
	LAST_ACTIVE_TIME_KEY,
	REFRESH_TOKEN_KEY,
} from 'constants/extendedWarranty';
import { ServiceType } from 'types/common';
import configureStore from 'store/configureStore';
import authSlice from 'store/modules/extendedWarranty/auth';
import ua from 'libs/userAgent';
import { isExpiredSession } from 'libs/extendedWarranty';

function clearAuthInfo(): void {
	localStorage.removeItem(ACCESS_TOKEN_KEY);
	localStorage.removeItem(REFRESH_TOKEN_KEY);
	localStorage.setItem(LAST_ACTIVE_TIME_KEY, dayjs().format('x'));
	const { dispatch } = configureStore(ServiceType.ENCAR_WARRANTY);
	dispatch(authSlice.actions.setAuthInfo({
		user: null,
		isLoggedIn: false,
	}));
}

export const renewToken = (refreshToken: string): AxiosPromise => ewAxios.post(
	`${getMeyleApiUrl()}/meyle/login`,
	null,
	{
		headers: {
			Authorization: `Bearer ${refreshToken}`,
		},
	},
);

ewAxios.interceptors.request.use((config) => {
	if (ua().isPc && isExpiredSession()) {
		clearAuthInfo();
		return Promise.reject();
	}

	return {
		...config,
		headers: { Authorization: config.headers.Authorization || `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}` },
	};
});

ewAxios.interceptors.response.use(
	(res) => res,
	async (err) => {
		if (err?.response?.status === 401) {
			const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
			const authHeader = err.config.headers.Authorization;
			const isRenewTokenRequest = err.config.url.indexOf('/meyle/login') > -1 && authHeader.indexOf('Bearer') > -1;

			if (refreshToken && !isRenewTokenRequest) {
				try {
					const authResponse = await renewToken(refreshToken);
					localStorage.setItem(ACCESS_TOKEN_KEY, authResponse.data.access_token);
					localStorage.setItem(REFRESH_TOKEN_KEY, authResponse.data.refresh_token);
					return ewAxios.request({
						...err.config,
						headers: {
							...err.config.headers,
							Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
						},
					});
				} catch (e) {
					console.error(e);
				}
			}
			clearAuthInfo();
		}
		throw err;
	},
);

export const login = (id: string, password: string): AxiosPromise => ewAxios.post(
	`${getMeyleApiUrl()}/meyle/login`,
	null,
	{
		headers: {
			Authorization: `Basic ${Base64.encodeURI(`${id}:${password}`)}`,
		},
	},
);

export const getDailyReservations = (
	params: ReservationDailyRequestParams,
): AxiosPromise => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/reservations/daily`,
	{ params },
);

export const getHourlyReservations = (
	params: ReservationHourlyRequestParams,
): AxiosPromise => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/reservations/hourly`,
	{ params },
);

export const getInspections = (
	params: InspectionRequestParams,
	cancelToken?: CancelToken,
): AxiosPromise => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/inspections`,
	{ params, cancelToken },
);

export const getInspectionHistories = (
	params: InspectionHistoryRequestParams,
	cancelToken?: CancelToken,
): AxiosPromise<InspectionDetail[]> => ewAxios.get(`${getMeyleApiUrl()}/meyle/cars/inspections`, {
	params,
	cancelToken,
});

export const getInspectionById = (
	id: number,
): AxiosPromise<InspectionDetail> => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/inspections/${id}`,
);

export const getInspectionHistoryByCarId = async (
	carId: number,
	status?: InspectionStatus,
): Promise<InspectionDetail | undefined> => {
	const { data } = await ewAxios.get<InspectionDetail[]>(
		`${getMeyleApiUrl()}/meyle/cars/inspections`,
		{ params: { carIds: [carId] } },
	);

	return status ? data.filter((i) => i.status === status)?.[0] : data[0];
};

export const getRepairs = (params: RepairsRequestParams): AxiosPromise => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/repairs/claims`,
	{ params },
);

export const getRepairById = (id: number): AxiosPromise => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/repairs/${id}`,
);

export const getVouchers = (params: VoucherRequestParams): AxiosPromise => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/repairs/vouchers`,
	{ params },
);

export const getBranchById = (id: number): AxiosPromise => ewAxios.get(`${getMeyleApiUrl()}/meyle/branches/${id}`);

export const getBranches = (params: BranchesRequestParams): AxiosPromise => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/branches`,
	{ params },
);

export const getSimpleBranches = (
	params?: BranchesSimpleRequestParams,
): AxiosPromise => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/branches/simple`,
	{ params },
);

export const putInspectionResult = (
	id: number,
	data: InspectionResultRequestData,
	cancelToken?: CancelToken,
): AxiosPromise => ewAxios.put(
	`${getMeyleApiUrl()}/meyle/inspections/${id}`,
	data,
	{ cancelToken },
);

export const postInspectionResult = (
	id: number,
	data: InspectionResultRequestData,
	cancelToken?: CancelToken,
): AxiosPromise => ewAxios.post(
	`${getMeyleApiUrl()}/meyle/inspections/${id}`,
	data,
	{ cancelToken },
);

export const changeInspectionReservationDateTime = (
	id: number,
	changeDateTime: Dayjs,
): AxiosPromise => ewAxios.put(
	`${getMeyleApiUrl()}/meyle/inspections/${id}/reservations`,
	{ reservedAt: changeDateTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ').split('.')[0] },
);

export const changeRepairReservationDateTime = (
	id: number,
	changeDateTime: Dayjs,
): AxiosPromise => ewAxios.put(
	`${getMeyleApiUrl()}/meyle/repairs/${id}/reservations`,
	{ reservedAt: changeDateTime.format('YYYY-MM-DDTHH:mm:ss.SSSZ').split('.')[0] },
);

export const postRepairEstimate = (
	id: number,
	data: RepairEstimateRequestData,
): AxiosPromise => ewAxios.post(
	`${getMeyleApiUrl()}/meyle/repairs/${id}/estimates`,
	data,
);

export const postRepairReservation = (
	id: number,
	branchId: number,
	reservedAt: Dayjs,
): AxiosPromise => ewAxios.post(
	`${getMeyleApiUrl()}/meyle/repairs/${id}/reservations`,
	{
		branchId,
		reservedAt: reservedAt.clone().startOf('minute').format('YYYY-MM-DDTHH:mm:ss.SSSZ').split('.')[0],
	},
);

export const getProducts = (): AxiosPromise => ewAxios.get(
	`${getApiUrl()}/legacy/product/single/extendwarrant`,
);

export const approvalRepairHq = (id: number, isApproval: boolean): AxiosPromise => ewAxios.put(
	`${getMeyleApiUrl()}/meyle/headquarter/repairs/${id}/approval`,
	{ approval: isApproval },
);

export const approvalRepairEncar = (
	id: number,
	isApproval: boolean,
	userCharge = 0,
): AxiosPromise => ewAxios.put(
	`${getMeyleApiUrl()}/meyle/encar/repairs/${id}/approval`,
	{ approval: isApproval, userCharge },
);

export const getPartByCode = (code: string): AxiosPromise => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/repair-parts/${code}`,
);

export const getWorkingList = (branchId: number): AxiosPromise => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/reservations/repairs/UNRELEASED`,
	{ params: { branchId } },
);

export const loggingExcelDownloadReason = (
	data: ExcelDownloadLoggingRequestParams,
): AxiosPromise => commonApi.post(
	`${getApiUrl()}/legacy/user/admin/filedownload/log`,
	data,
);

export const getHolidays = (from: Dayjs, to: Dayjs): AxiosPromise => ewAxios.get(
	`${getMeyleApiUrl()}/meyle/holiday`,
	{
		params: {
			from: from.format(HYPHEN_DATE_FORMAT),
			to: to.format(HYPHEN_DATE_FORMAT),
		},
	},
);
