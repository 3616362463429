import React, { ReactElement } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames/bind';
import styles from './ServiceLoader.module.scss';

const cx = classNames.bind(styles);

function ServiceLoader(): ReactElement {
	return (
		<div className={cx('loading')}>
			<Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
		</div>
	);
}

export default ServiceLoader;
