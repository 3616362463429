import { Store, Action, Dispatch } from '@reduxjs/toolkit';
import { loggingException } from 'libs/sentry';

/* eslint-disable */
const crashReporter = (_: Store) => (next: Dispatch) => (action: Action): any => {
	try {
		return next(action); // dispatch
	} catch (error) {
		console.error('Caught an exception!', error);
		loggingException(error, { action });
		throw error; // re-throw error
	}
};

export default crashReporter;
