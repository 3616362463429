import React, { useRef, useCallback, ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { message, Modal } from 'antd';
import dayjs from 'libs/dayjs';
import ExcelDownloadReasonForm from 'components/extendedWarranty/admin/common/ExcelDownloadReasonForm';
import * as ewApi from 'api/extendedWarranty';
import useCurrentUser from 'hooks/extendedWarranty/common/useCurrentUser';

interface CallOptions {
	onSuccess?: () => void;
	onCancel?: () => void;
}

interface Return {
	openExcelDownloadReasonModal: (options: CallOptions) => void;
}

function useExcelDownloadReason(): Return {
	const { currentUser } = useCurrentUser();
	const location = useLocation();
	const downloadReason = useRef('');

	const handleDownloadReason = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
		downloadReason.current = e.target.value;
	}, []);

	const handleOk = useCallback((success, close): null | Promise<void> => {
		if (!currentUser) {
			message.warning('사용자를 찾을 수 없습니다.');
			return null;
		}
		if (!downloadReason.current) {
			message.warning('다운로드 사유를 입력해주세요.');
			return null;
		}

		const { origin, pathname } = window.location;
		return ewApi.loggingExcelDownloadReason({
			downDate: Date.now(),
			empId: currentUser.id,
			empNm: currentUser.name,
			ip: '0.0.0.0',
			link: location.pathname,
			menuNm: '',
			pageId: 'meyle',
			pageType: 'EXCEL',
			reason: downloadReason.current,
			result: '',
			url: `${origin}${pathname}`,
		}).then(() => {
			success();
			close();
		}).catch((e: Error) => {
			console.log(e);
			message.error('엑셀다운로드 사유 등록에 실패했습니다. 다시 시도해주세요.');
		});
	}, [currentUser, location.pathname]);

	const openExcelDownloadReasonModal = useCallback(({ onSuccess, onCancel }: CallOptions) => {
		if (!currentUser) {
			message.warning('사용자를 찾을 수 없습니다.');
			return;
		}
		const now = dayjs();
		const date = now.format('YYYY/MM/DD');
		const time = now.format('HH:mm:ss');
		downloadReason.current = '';
		Modal.confirm({
			icon: null,
			title: '엑셀파일 다운로드 사유입력',
			content: (
				<ExcelDownloadReasonForm
					date={date}
					time={time}
					id={currentUser.id}
					ip="0.0.0.0"
					onChangeDownloadReason={handleDownloadReason}
				/>
			),
			onOk: (close) => handleOk(onSuccess, close),
			onCancel,
		});
	}, [currentUser, handleOk, handleDownloadReason]);

	return { openExcelDownloadReasonModal };
}

export default useExcelDownloadReason;
