import axios from 'axios';
import qs from 'query-string';
import { api, meyleApi, buildEnv } from 'env';

const apiURL = api[buildEnv];
const meyleApiURL = meyleApi[buildEnv];

export const getApiUrl = (stage?: string): string => (
	(buildEnv !== 'dev' && buildEnv !== 'prod' && !!stage)
		? stage
		: apiURL);
export const getMeyleApiUrl = (stage?: string): string => (
	(buildEnv !== 'dev' && buildEnv !== 'prod' && !!stage)
		? stage
		: meyleApiURL);

export const commonApi = axios.create({ baseURL: api[buildEnv] });
export const ewAxios = axios.create({ paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'none' }) });
