import React, { useState, useCallback, ChangeEvent } from 'react';
import { numberRegExp } from 'constants/regularExpressions';

type Value = string;

interface Handler {
	(e: ChangeEvent<HTMLInputElement>): void;
}

interface Options {
	onlyNumber?: boolean;
	max?: number;
}

type Return = [Value, Handler, React.Dispatch<React.SetStateAction<string>>];

function useDateRangeState(initialState: Value, options?: Options): Return {
	const { onlyNumber, max } = options || {};
	const [value, setValue] = useState(initialState);
	const handler = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
		const newValue = e.target.value;
		if (newValue !== '' && onlyNumber && !numberRegExp.test(newValue)) return;
		if (newValue !== '' && max && newValue.length > max) return;

		setValue(newValue);
	}, [onlyNumber, max]);

	return [value, handler, setValue];
}

export default useDateRangeState;
