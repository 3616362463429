import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as ewApi from 'api/extendedWarranty';
import { CancelToken } from 'axios';
import { InspectionDetail } from 'types/api/extendedWarranty';
import { InspectionRequestParams } from 'types/extendedWarranty/inspect';

interface ReservationState {
	list: InspectionDetail[] | null;
	totalCount: number;
	loading: boolean;
	error: boolean;
}

export const initialState: ReservationState = {
	list: null,
	totalCount: 0,
	loading: false,
	error: false,
};

interface FetchReservationsPayload {
	params: InspectionRequestParams;
	cancelToken: CancelToken;
}

export const fetchReservations = createAsyncThunk(
	'reservation/fetchReservations',
	async ({ params, cancelToken }: FetchReservationsPayload) => {
		const { data } = await ewApi.getInspections(params, cancelToken);
		return data;
	},
);

export default createSlice({
	name: 'reservation',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchReservations.pending, (state): void => {
			state.loading = true;
			state.error = false;
		});
		builder.addCase(fetchReservations.rejected, (state, action): void => {
			state.loading = false;
			if (action.error.message !== 'Cancel') state.error = true;
		});
		builder.addCase(fetchReservations.fulfilled, (state, action): void => {
			state.loading = false;
			state.list = action.payload.inspections;
			state.totalCount = action.payload.totalCount;
		});
	},
});
