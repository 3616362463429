import React, { ChangeEvent, ReactElement } from 'react';
import {
	Col,
	Row,
	Input,
	Divider,
	Typography,
} from 'antd';
import classNames from 'classnames/bind';
import styles from './ExcelDownloadReasonForm.module.scss';

const cx = classNames.bind(styles);
const { Text } = Typography;
const { TextArea } = Input;

interface Props {
	date: string;
	time: string;
	id: string;
	ip: string;
	onChangeDownloadReason: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

function ExcelDownloadReasonForm({
	date,
	time,
	id,
	onChangeDownloadReason,
	ip,
}: Props): ReactElement {
	console.log(ip);
	return (
		<>
			<Text>*개인정보보호법에 따라 엑셀 다운로드 사유를 수집합니다</Text>
			<Divider />
			<Row>
				<Col span={12}>날짜 : {date}</Col>
				<Col span={12}>시간 : {time}</Col>
			</Row>
			<Row>
				<Col span={24}>ID : {id}</Col>
			</Row>
			<Divider />
			<Text strong className={cx('input-title')}>다운로드 사유 입력</Text>
			<TextArea
				rows={6}
				onChange={onChangeDownloadReason}
			/>
		</>
	);
}

export default ExcelDownloadReasonForm;
