import { shallowEqual, useSelector } from 'react-redux';
import { ExtendedWarrantyState } from 'store/modules/extendedWarranty';
import { User } from 'types/extendedWarranty/common';
import { ACCESS_TOKEN_KEY, UserType } from 'constants/extendedWarranty';
import { getBranchIdByJwt } from 'libs/jwt';

interface Return {
	currentUser: User | null;
	currentUserBranchId: number | null;
	isAdmin: boolean;
	isHq: boolean;
	isEmployee: boolean;
}

function useCurrentUser(): Return {
	const accessJwt = localStorage.getItem(ACCESS_TOKEN_KEY);
	const { user } = useSelector((state: ExtendedWarrantyState) => state.auth, shallowEqual);
	const currentUserBranchId = getBranchIdByJwt(accessJwt);

	return {
		currentUser: user,
		currentUserBranchId,
		isAdmin: user?.userType === UserType.MEYLE_ADMIN,
		isHq: user?.userType === UserType.MEYLE_HQ,
		isEmployee: user?.userType === UserType.MEYLE_EMPLOYEE,
	};
}

export default useCurrentUser;
