import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import {
	sentryDsn,
	buildEnv,
} from 'env';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const sentryDsnCheck = sentryDsn[buildEnv];
if (sentryDsnCheck !== '' && sentryDsnCheck !== undefined) {
	Sentry.init({
		dsn: sentryDsnCheck,
		environment: process.env.REACT_APP_ENV,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
	});
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
);
