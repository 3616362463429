import {
	FlattenRepair,
	LaborCost,
	RepairPart,
	RepairPartPhoto,
} from 'types/extendedWarranty/repair';
import {
	ExtendedRepairDetail,
	ExtendedVoucherDetail,
	InspectionDetail,
	Labor as LaborData,
	Payment,
	RepairDetail,
	RepairPart as RepairPartData,
	ReservationByHourly,
} from 'types/api/extendedWarranty';
import {
	InspectionStatus,
	LABOR_COST_PER_HOUR,
	LAST_ACTIVE_TIME_KEY,
	PaymentStatus,
	RepairStatus,
	ReservationTypes,
	UserType,
} from 'constants/extendedWarranty';
import { pictureToUploadFileList } from 'libs/file';
import dayjs from 'libs/dayjs';
import { ColumnType } from 'antd/es/table';
import * as ewApi from 'api/extendedWarranty';
import ua from 'libs/userAgent';
import qs from 'query-string';

const checkValidFileList = (fileList?: RepairPartPhoto): boolean => !!(
	(fileList?.beforeDetachPhoto && fileList.beforeDetachPhoto.length > 0)
	|| (fileList?.afterDetachPhoto && fileList.afterDetachPhoto.length > 0)
	|| (fileList?.beforeMountPhoto && fileList.beforeMountPhoto.length > 0)
	|| (fileList?.afterMountPhoto && fileList.afterMountPhoto.length > 0)
);

export const checkValidPart = (part: RepairPart): boolean => !!(
	part.partNumber
		|| part.partName
		|| part.partPrice
		|| part.partCount
		|| checkValidFileList(part.fileList)
);

export const checkValidLabor = (labor: LaborCost): boolean => !!(labor.work || labor.hour);
export const getDefaultRepairPart = (): RepairPart => ({
	id: Date.now(),
	isNew: true,
	partCount: 1,
});
export const getDefaultLabor = (): LaborCost => ({ id: 1 });

export const sumTotalPartCost = (acc: number, cur: RepairPart): number => (
	acc + (cur.partPrice || 0) * (cur.partCount || 0));

export const sumTotalLaborCost = (acc: number, cur: LaborCost): number => (
	acc + (cur.hour || 0) * LABOR_COST_PER_HOUR);

export const sumTotalPartDataCost = (acc: number, cur: RepairPartData): number => (
	acc + (cur.unitPrice || 0) * (cur.quantity || 0));

export const sumTotalLaborDataCost = (acc: number, cur: LaborData): number => (
	acc + (cur.price || 0));

export const parseRepairPartsToInitData = (
	repairParts?: RepairPartData[],
): RepairPart[] => (repairParts && repairParts.length > 0
	? repairParts.map((p): RepairPart => ({
		id: p.id,
		partNumber: p.code,
		partName: p.name,
		partPrice: p.unitPrice,
		partCount: p.quantity,
		registeredPart: p.tabled ? {
			code: p.code,
			name: p.name,
			unitPrice: p.unitPrice,
		} : undefined,
		fileList: {
			beforeDetachPhoto: pictureToUploadFileList(p.beforeDetach),
			afterDetachPhoto: pictureToUploadFileList(p.afterDetach),
			beforeMountPhoto: pictureToUploadFileList(p.beforeMount),
			afterMountPhoto: pictureToUploadFileList(p.afterMount),
		},
		isNew: false,
	})) : [getDefaultRepairPart()]);

export const parseRepairLaborsToInitData = (
	labors?: LaborData[],
): LaborCost[] => (labors && labors.length > 0
	? labors.map((l, i) => ({
		id: i,
		work: l.contents,
		hour: l.hour,
		price: l.price,
	})) : [getDefaultLabor()]);

export const enableInspectionReservationChange = (inspection?: InspectionDetail): boolean => {
	if (!inspection) return false;
	const isWait = inspection.status === InspectionStatus.WAIT;
	const isNoShow = inspection.status === InspectionStatus.NO_SHOW;
	const isChangedTime = inspection.reservation.timeChanged;
	const isTodayReservation = dayjs(inspection.reservation.reservedAt)
		.clone()
		.startOf('day')
		.isSame(dayjs().startOf('day'));

	return (isWait || isNoShow) && !isTodayReservation && !isChangedTime;
};

const checkEmployeeBlindColumn = <T>(column: ColumnType<T>): boolean => {
	if (column.key === 'branchName') return false;
	if (column.key === 'carId') return false;
	if (column.key === 'payAmount') return false;
	if (column.key === 'warrantyCode') return false;
	return true;
};
export const employeeFilter = (isEmployee: boolean) => <T>(column: ColumnType<T>): boolean => (
	isEmployee
		? checkEmployeeBlindColumn(column)
		: true);

export const includeInspection = async <T extends ExtendedRepairDetail | ExtendedVoucherDetail>(
	data: T[],
): Promise<T[]> => {
	const carIds = data
		.filter((repair) => repair.car?.id)
		.map((repair) => repair.car?.id);
	const filteredCarIds = Array.from(new Set(carIds));
	const includedRepairsData = [...data];

	if (filteredCarIds?.length > 0) {
		const { data: inspections } = await ewApi.getInspectionHistories({
			carIds: filteredCarIds as number[],
		});
		const filteredInspections = inspections
			.filter((inspection) => inspection.status === InspectionStatus.COMPLETE_WITH_EW);

		data.forEach((repair, i): void => {
			const findInspection: InspectionDetail | undefined = filteredInspections
				.find((inspection: InspectionDetail) => inspection.car?.id === repair.car?.id);

			if (findInspection) {
				includedRepairsData[i].inspectionByCarId = findInspection;
			}
		});
	}

	return data;
};

export const getWarrantyProductName = (payment?: Payment): string => `${payment?.product?.name || ''} ${payment?.product?.month || ''}개월`;

/** 완료 상태 기준
 * 참고 (https://miro.com/app/board/o9J_kudqciU=/?moveToWidget=3074457348754815114&cot=3)
 */
export const checkIsCompleted = (item: ReservationByHourly): boolean => {
	if (item.reservation.type === ReservationTypes.INSPECTION) {
		return item.inspectionStatus === InspectionStatus.COMPLETE_WITH_EW
			|| item.inspectionStatus === InspectionStatus.COMPLETE_WITHOUT_EW;
	}
	if (item.reservation.type === ReservationTypes.REPAIR) {
		return item.repairStatus === RepairStatus.COMPLETE;
	}
	return false;
};

export const isExpiredSession = (): boolean => {
	const lastActiveTime = localStorage.getItem(LAST_ACTIVE_TIME_KEY);
	const diffMinutes = lastActiveTime && dayjs()
		.diff(dayjs(parseInt(lastActiveTime, 10)), 'minutes', true);

	return !lastActiveTime || (diffMinutes != null && diffMinutes > 30);
};

export const flatRepairs = (repairs: RepairDetail[]): FlattenRepair[] => (
	repairs.reduce<FlattenRepair[]>((acc, cur) => {
		const maxRowSize = Math.max(cur.repairParts?.length || 0, cur.labors?.length || 0);
		const minRowSize = Math.min(cur.repairParts?.length || 0, cur.labors?.length || 0);

		if (maxRowSize === 0) {
			acc.push(cur);
			return acc;
		}

		for (let i = 0; i < maxRowSize; i += 1) {
			acc.push({
				...cur,
				id: i === 0 ? cur.id : (-cur.id - (i * 100)),
				flattenRepairPart: cur.repairParts?.[i],
				flattenLabor: cur.labors?.[i],
				maxRowSize: i === 0 ? maxRowSize : 0,
				childIndex: i,
				emptyRowSpan: maxRowSize - minRowSize,
			});
		}

		return acc;
	}, []));

export const checkDisableInspectionResultInput = (inspection?: InspectionDetail): boolean => {
	const isComplete = inspection?.status === InspectionStatus.COMPLETE_WITH_EW
		|| inspection?.status === InspectionStatus.COMPLETE_WITHOUT_EW;
	const isCancel = inspection?.status === InspectionStatus.CANCEL;
	const isDepositWait = inspection?.payment?.status === PaymentStatus.DEPOSIT_WAIT;

	return !inspection || isComplete || isCancel || isDepositWait;
};

export const getMainPageUrl = (userType: UserType): string => {
	const params = qs.parse(window.location.search);
	const returnUrl = params.returnUrl as string;

	if (userType === UserType.MEYLE_ADMIN || userType === UserType.MEYLE_HQ) return '/warranty/admin/inspection/register';
	if (ua().isMobileOrTablet) return '/warranty/m/reservation';
	if (returnUrl) return returnUrl;
	return '/warranty/admin/reservation/calendar';
};

export const checkRepairPartCostChange = (
	oldParts?: RepairPartData[],
	newInputParts?: RepairPart[],
): boolean => {
	const validParts = newInputParts?.filter(checkValidPart);
	const isDiffPartsSize = oldParts?.length !== validParts?.length;
	const isDiffPartCost = !validParts?.every((newPart) => (
		oldParts?.some((oldPart) => (
			newPart.partCount === oldPart.quantity
			&& newPart.partPrice === oldPart.unitPrice
		))
	));

	return isDiffPartsSize || isDiffPartCost;
};

export const checkRepairLaborCostChange = (
	oldLabors?: LaborData[],
	newInputLabors?: LaborCost[],
): boolean => {
	const validLabors = newInputLabors?.filter(checkValidLabor);
	const isDiffLaborSize = oldLabors?.length !== validLabors?.length;
	const isDiffLaborCost = !validLabors?.every((newLabor) => (
		oldLabors?.some((oldLabor) => (newLabor.hour === oldLabor.hour))));

	return isDiffLaborSize || isDiffLaborCost;
};
