import {
	useState,
	Dispatch,
	useCallback,
	ChangeEvent,
	SetStateAction,
} from 'react';

interface Handler {
	(e: ChangeEvent<HTMLTextAreaElement>): void;
}

type Return = [
	string,
	Handler,
	Dispatch<SetStateAction<string>>,
];

function useTextAreaState(initialState: string): Return {
	const [value, setValue] = useState(initialState);
	const handler = useCallback((e: ChangeEvent<HTMLTextAreaElement>): void => {
		setValue(e.target.value);
	}, []);

	return [value, handler, setValue];
}

export default useTextAreaState;
