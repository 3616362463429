import React, { ReactElement, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ServiceLoader from 'components/common/ServiceLoader';
import { ExtendedWarranty, Dealer } from 'services';
import NotFoundPage from 'pages/common/NotFoundPage';
import ServiceGate from 'pages/common/ServiceGate';
import { Helmet } from 'react-helmet';
import ua from 'libs/userAgent';

function App(): ReactElement {
	return (
		<Router>
			<Helmet>
				<body className={ua().isPc ? 'isPc' : ''} />
			</Helmet>
			<Suspense fallback={<ServiceLoader />}>
				<Switch>
					<Route exact path="/"><ServiceGate /></Route>
					<Route path="/warranty"><ExtendedWarranty /></Route>
					<Route path="/dealer"><Dealer /></Route>
					<Route><NotFoundPage /></Route>
				</Switch>
			</Suspense>
		</Router>
	);
}

export default App;
