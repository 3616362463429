import { UAParser } from 'ua-parser-js';

interface Return {
	isApp: boolean;
	isPc: boolean;
	isMobile: boolean;
	isTablet: boolean;
	isMobileOrTablet: boolean;
	isIOS: boolean;
	isAndroid: boolean;
	isIE: boolean;
	isSafari: boolean;
	browser: UAParser.IBrowser;
	equalAndroid: (version: number) => boolean;
	lessThanAndroid: (version: number) => boolean;
	greaterThanAndroid: (version: number) => boolean;
	equalIE: (version: number) => boolean;
	lessThanIE: (version: number) => boolean;
	greaterThanIE: (version: number) => boolean;
	lessThanAppVersion: (version: string) => boolean;
	greaterThanAppVersion: (version: string) => boolean;
}

export default (): Return => {
	const { userAgent } = window.navigator;
	const webViewName = userAgent.toLowerCase().match(/(webview)_(android|ios|iphone)/g);
	const ua = new UAParser(userAgent).getResult();

	return {
		isApp: webViewName != null,
		isPc: ua.device.type == null,
		isMobile: ua.device.type === 'mobile',
		isTablet: ua.device.type === 'tablet',
		isMobileOrTablet: ua.device.type === 'mobile' || ua.device.type === 'tablet',
		isIOS: ua.os.name === 'iOS',
		isAndroid: ua.os.name === 'Android',
		isIE: ua.browser.name === 'IE',
		isSafari: ua.browser.name ? ua.browser.name.indexOf('Safari') > -1 : false,
		browser: ua.browser,
		equalAndroid: (version: number): boolean => {
			if (version == null) throw new Error('version을 전달해야 합니다.');
			return ua.os.name === 'Android' && ua.os.version ? parseInt(ua.os.version, 10) === version : false;
		},
		lessThanAndroid: (version: number): boolean => {
			if (version == null) throw new Error('version을 전달해야 합니다.');
			return ua.os.name === 'Android' && ua.os.version ? parseInt(ua.os.version, 10) < version : false;
		},
		greaterThanAndroid: (version: number): boolean => {
			if (version == null) throw new Error('version을 전달해야 합니다.');
			return ua.os.name === 'Android' && ua.os.version ? parseInt(ua.os.version, 10) > version : false;
		},
		equalIE: (version: number): boolean => {
			if (version == null) throw new Error('version을 전달해야 합니다.');
			return ua.browser.name === 'IE' && ua.browser.major ? parseInt(ua.browser.major, 10) === version : false;
		},
		lessThanIE: (version: number): boolean => {
			if (version == null) throw new Error('version을 전달해야 합니다.');
			return ua.browser.name === 'IE' && ua.browser.major ? parseInt(ua.browser.major, 10) < version : false;
		},
		greaterThanIE: (version: number): boolean => {
			if (version == null) throw new Error('version을 전달해야 합니다.');
			return ua.browser.name === 'IE' && ua.browser.major ? parseInt(ua.browser.major, 10) > version : false;
		},
		lessThanAppVersion: (version: string): boolean => {
			if (version == null) throw new Error('version을 x.x.x 형식으로 전달해 주세요');
			if (webViewName == null) return false;
			const appVersionInfo = userAgent.match(/version(([0-9].{1,}\d+)?)/g);
			const appVersion = appVersionInfo ? appVersionInfo.toString().match(/[0-9].{1,}\d+?$/g) : '';
			return (appVersion || '').toString().localeCompare(version) < 0;
		},
		greaterThanAppVersion: (version: string): boolean => {
			if (version == null) throw new Error('version을 x.x.x 형식으로 전달해 주세요');
			if (webViewName == null) return false;
			const appVersionInfo = userAgent.match(/version(([0-9].{1,}\d+)?)/g);
			const appVersion = appVersionInfo ? appVersionInfo.toString().match(/[0-9].{1,}\d+?$/g) : '';
			return version.localeCompare((appVersion || '').toString()) < 0;
		},
	};
};
