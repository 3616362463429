import { useCallback, useState } from 'react';

type Return = [boolean, (flag: boolean) => void];

function useLoading(initialLoading: boolean): Return {
	const [fetchCount, setFetchCount] = useState(initialLoading ? 1 : 0);
	const loading = fetchCount > 0;

	const handleLoading = useCallback((flag: boolean): void => {
		setFetchCount((prev) => (flag ? prev + 1 : prev - 1));
	}, []);

	return [loading, handleLoading];
}

export default useLoading;
