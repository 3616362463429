import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './auth';
import commonSlice from './common';
import reservationSlice from './reservation';
import workingSlice from './working';

const rootReducer = combineReducers({
	auth: authSlice.reducer,
	common: commonSlice.reducer,
	reservation: reservationSlice.reducer,
	working: workingSlice.reducer,
});

export default rootReducer;
export type ExtendedWarrantyState = ReturnType<typeof rootReducer>;
