import {
	useState,
	useCallback,
	Dispatch,
	SetStateAction,
} from 'react';
import { RadioChangeEvent } from 'antd/es/radio';

interface Handler {
	(e: RadioChangeEvent): void;
}

function useRadioState<T>(initialState: T): [T, Handler, Dispatch<SetStateAction<T>>] {
	const [value, setValue] = useState(initialState);
	const handler = useCallback((e: RadioChangeEvent): void => {
		setValue(e.target.value);
	}, []);

	return [value, handler, setValue];
}

export default useRadioState;
