import { UploadFile } from 'antd/es/upload/interface';
import { Picture } from 'types/api/extendedWarranty';
import loadImage from 'blueimp-load-image';

export const getBase64 = (file: File | Blob): Promise<string> => (
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (): void => {
			resolve(reader.result as string);
		};
		reader.onerror = (error): void => {
			reject(error);
		};
	})
);

export const getExtension = (url: string): string => url.substring(url.lastIndexOf('.'));

export const getMimeType = (url: string): string => {
	const extension = getExtension(url);
	const mime: { [index: string]: string } = {
		'.jpeg': 'image/jpeg',
		'.jpg': 'image/jpeg',
		'.gif': 'image/gif',
		'.png': 'image/png',
	};

	return mime[extension];
};

export const pictureToUploadFile = (picture?: Picture): UploadFile | undefined => {
	if (!picture || !picture.key || !picture.url || !picture.name) return undefined;

	return {
		uid: picture.key,
		url: picture.url,
		name: picture.name,
		type: getMimeType(picture.url),
		size: 0,
		status: 'done',
		response: {
			fileKey: picture.key,
			fileOrgName: picture.name,
			fileFullUrl: picture.url,
		},
	};
};

export const pictureToUploadFileList = (picture?: Picture): UploadFile[] => {
	const uploadFile = pictureToUploadFile(picture);
	return uploadFile ? [uploadFile] : [];
};

export const transformOrientationFile = (file: File): Promise<File> => (
	new Promise<File>(((resolve) => {
		loadImage(file, (img) => {
			(img as HTMLCanvasElement).toBlob((blob) => {
				if (blob) {
					const newFile = new File([blob], file.name, { ...file });
					resolve(newFile);
				}
			}, file.type);
		},
		{
			canvas: true,
			orientation: true,
			maxWidth: 2000,
		});
	}))
);
