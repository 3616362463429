export const styles = {
	PRIMARY_COLOR: '#185882',
};

export const LAST_ACTIVE_TIME_KEY = 'last_active_time';
export const ACCESS_TOKEN_KEY = 'ew_access_token';
export const REFRESH_TOKEN_KEY = 'ew_refresh_token';

export const ReservationHours = [9, 10, 11, 13, 14, 15, 16, 17, 18] as const;

export const HYPHEN_DATE_FORMAT = 'YYYY-MM-DD';
export const COMMA_DATE_FORMAT = 'YYYY.MM.DD';
export const SLASH_DATE_FORMAT = 'YYYY/MM/DD';
export const SIMPLE_DATE_FORMAT = 'YY/MM/DD';
export const SIMPLE_DATETIME_FORMAT = 'YY/MM/DD (HH:mm)';

export enum ReservationTypes {
	INSPECTION = 'INSPECTION',
	REPAIR = 'REPAIR',
	VOUCHER = 'VOUCHER',
}

export enum Days {
	SUN,
	MON,
	TUE,
	WED,
	THU,
	FRI,
	SAT,
}

export enum UplusPaymentMethod {
	CREDIT_CARD = 'CREDIT_CARD',
	AUTO_CREDIT_CARD = 'AUTO_CREDIT_CARD',
	OK_CASHBAG = 'OK_CASHBAG',
	CELLPHONE = 'CELLPHONE',
	POS = 'POS',
	VIRTUAL_ACCOUNT = 'VIRTUAL_ACCOUNT',
	ACCOUNT = 'ACCOUNT',
	CARD_SWIPER = 'CARD_SWIPER',
	GOOGLE_PAY = 'GOOGLE_PAY',
	CASH = 'CASH',
	COMPANY_ACCOUNT = 'COMPANY_ACCOUNT',
	COUPON = 'COUPON',
	POINT = 'POINT',
	SERVICE = 'SERVICE',
	COOPERATE = 'COOPERATE',
	FIRM_COOPERATE = 'FIRM_COOPERATE',
	CREDIT = 'CREDIT',
	NOTE_RECEIVALES = 'NOTE_RECEIVALES',
}

export const UplusPaymentMethodText = {
	[UplusPaymentMethod.CREDIT_CARD]: '신용카드',
	[UplusPaymentMethod.AUTO_CREDIT_CARD]: '신용카드(정기결제)',
	[UplusPaymentMethod.OK_CASHBAG]: 'OK캐시백',
	[UplusPaymentMethod.CELLPHONE]: '휴대폰',
	[UplusPaymentMethod.POS]: '안심키인(POS결제)',
	[UplusPaymentMethod.VIRTUAL_ACCOUNT]: '무통장',
	[UplusPaymentMethod.ACCOUNT]: '계좌이체',
	[UplusPaymentMethod.CARD_SWIPER]: '단말기',
	[UplusPaymentMethod.GOOGLE_PAY]: '구글 페이',
	[UplusPaymentMethod.CASH]: '현금',
	[UplusPaymentMethod.COMPANY_ACCOUNT]: '본부통장',
	[UplusPaymentMethod.COUPON]: '쿠폰',
	[UplusPaymentMethod.POINT]: '포인트',
	[UplusPaymentMethod.SERVICE]: '서비스',
	[UplusPaymentMethod.COOPERATE]: '단지제휴',
	[UplusPaymentMethod.FIRM_COOPERATE]: '상사제휴',
	[UplusPaymentMethod.CREDIT]: '신용',
	[UplusPaymentMethod.NOTE_RECEIVALES]: '미수금',
};

export enum ReservationStatus {
	CREATED = 'CREATED',
	WAIT = 'WAIT',
	PROGRESS = 'PROGRESS',
	COMPLETE = 'COMPLETE',
	NO_SHOW = 'NO_SHOW',
	CANCEL = 'CANCEL',
}

export enum RepairStatus {
	BEFORE_RECEIPT = 'BEFORE_RECEIPT',
	RECEIPT = 'RECEIPT',
	BEFORE = 'BEFORE',
	PROGRESS = 'PROGRESS',
	COMPLETE = 'COMPLETE',
	NO_SHOW = 'NO_SHOW',
}

export enum VoucherStatus {
	BEFORE_RECEIPT = 'BEFORE_RECEIPT',
	RECEIPT = 'RECEIPT',
	BEFORE = 'BEFORE',
	PROGRESS = 'PROGRESS',
	COMPLETE = 'COMPLETE',
	NO_SHOW = 'NO_SHOW',
}

export enum InspectionStatus {
	WAIT = 'WAIT',
	PROGRESS = 'PROGRESS',
	COMPLETE_WITH_EW = 'COMPLETE_WITH_EW',
	COMPLETE_WITHOUT_EW = 'COMPLETE_WITHOUT_EW',
	NO_SHOW = 'NO_SHOW',
	CANCEL = 'CANCEL',
}

export const InspectionStatusText = {
	[InspectionStatus.WAIT]: '점검전',
	[InspectionStatus.PROGRESS]: '점검중',
	[InspectionStatus.COMPLETE_WITH_EW]: '완료(가입)',
	[InspectionStatus.COMPLETE_WITHOUT_EW]: '완료(미가입)',
	[InspectionStatus.NO_SHOW]: '미점검',
	[InspectionStatus.CANCEL]: '취소',
};

export enum UnRegisterReasons {
	차량이상 = '차량이상',
	단순변심 = '단순변심',
	미점검 = '미점검',
	중복자동취소 = '중복자동취소',
}

export const LABOR_COST_PER_HOUR = 77000;

export const EXCEL_FETCH_SIZE = 20;

export enum ClaimedAtFromRegistered {
	BEFORE_31_DAYS,
	AFTER_30_DAYS,
}

export enum ColumnClassNames {
	PRE_LINE = 'pre_line',
}

export const Errors = {
	Code: {
		ALREADY_WARRANTED_CAR: '50032',
	},
	Message: {
		FETCH_INSPECTIONS_FAILURE: '예약정보를 불러오지 못했습니다. 다시 시도해주세요.',
		REPAIR_APPROVAL_FAILURE: '승인에 실패했습니다.',
		REPAIR_APPROVAL_CANCEL_FAILURE: '승인 취소에 실패했습니다.',
		REPAIR_APPROVAL_UPDATE_FAILURE: '수정에 실패했습니다.',
		ALREADY_WARRANTED_CAR: '이미 엔카보증 가입완료된 차량입니다.',
	},
};

export const Message = {
	LOGOUT_CONFIRM: '로그아웃 하시겠습니까?',
	LOGOUT_SUCCESS: '로그아웃 되었습니다.',
	RESERVATION_CHANGE_SUCCESS: '예약이 변경되었습니다.',
	APPROVAL_SUCCESS: '승인 되었습니다.',
	APPROVAL_CANCEL_SUCCESS: '승인 취소 되었습니다.',
	APPROVAL_RESET: '부품비 또는 공임비가 변경되어 승인이 취소되었습니다.',
};

export enum UpdateOrCancel {
	UPDATE,
	CANCEL,
}

export enum RepairOrderBy {
	RECEIPTED_AT_ASC = 'RECEIPTED_AT_ASC',
	RECEIPTED_AT_DESC = 'RECEIPTED_AT_DESC',
	RESERVED_AT_ASC = 'RESERVED_AT_ASC',
	RESERVED_AT_DESC = 'RESERVED_AT_DESC',
	COMPLETED_AT_ASC = 'COMPLETED_AT_ASC',
	COMPLETED_AT_DESC = 'COMPLETED_AT_DESC',
}

export enum VoucherOrderBy {
	RECEIPTED_AT_ASC = 'RECEIPTED_AT_ASC',
	RECEIPTED_AT_DESC = 'RECEIPTED_AT_DESC',
	RESERVED_AT_ASC = 'RESERVED_AT_ASC',
	RESERVED_AT_DESC = 'RESERVED_AT_DESC',
	COMPLETED_AT_ASC = 'COMPLETED_AT_ASC',
	COMPLETED_AT_DESC = 'COMPLETED_AT_DESC',
}

export enum UserType {
	MEYLE_ADMIN = 'MEYLE_ADMIN',
	MEYLE_HQ = 'MEYLE_HQ',
	MEYLE_EMPLOYEE = 'MEYLE_EMPLOYEE',
}

export enum EncarApprovalType {
	APPROVAL,
	CANCEL,
	CHANGE_USER_CHARGE,
}

export enum RepairType {
	CLAIM,
	VOUCHER,
}

export const SAVED_ID_STORAGE_KEY = 'saved_id';

export enum PaymentStatus {
	DEPOSIT_WAIT = 'DEPOSIT_WAIT',
	NON_DEPOSIT = 'NON_DEPOSIT',
	PAYED = 'PAYED',
	CANCELED = 'CANCELED',
}

export enum MaskingType {
	NAME,
	PHONE,
	CAR_PLATE,
}

export enum WarrantyStatus {
	VALID = 'VALID',
	EXPIRED = 'EXPIRED',
	REFUNDED = 'REFUNDED',
}

export const REPAIR_CONTENTS_MAX_LENGTH = 1000;
